import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './pages/init/init.module#InitModule' },
  { path: 'press', loadChildren: './pages/press/press.module#PressModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutModule' },
  { path: 'terms', loadChildren: './pages/terms/terms.module#TermsModule' },
  { path: 'subscribe', loadChildren: './pages/subscribe/subscribe.module#SubscribeModule' },
  { path: 'unsubscribe', loadChildren: './pages/unsubscribed/unsubscribed.module#UnsubscribedModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
  { path: '', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
